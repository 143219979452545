// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Icon } from '@/components';
import LedgerLogoNoTextGreen from "@/static/images/LedgerNoTextGreen.png"

import '@/style/components/BigcapitalLoading.scss';

/**
 * Bigcapital logo loading.
 */
export default function BigcapitalLoading({ className }) {
  return (
    <div className={classNames('bigcapital-loading', className)}>
      <div className="center">
        {/* <Icon icon="bigcapital" height={37} width={228} /> */}
        <img src={LedgerLogoNoTextGreen} alt="Ledger Logo No Text Green" loading="lazy" className="" />
      </div>
    </div>
  );
}
