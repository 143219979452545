// @ts-nocheck
export enum DRAWERS {
  ACCOUNT_DETAILS = 'account-drawer',
  JOURNAL_DETAILS = 'journal-drawer',
  EXPENSE_DETAILS = 'expense-drawer',
  BILL_DETAILS = 'bill-drawer',
  INVOICE_DETAILS = 'invoice-detail-drawer',
  RECEIPT_DETAILS = 'receipt-detail-drawer',
  PAYMENT_RECEIVE_DETAILS = 'payment-receive-detail-drawer',
  PAYMENT_MADE_DETAILS = 'payment-made-drawer',
  ESTIMATE_DETAILS = 'estimate-detail-drawer',
  ITEM_DETAILS = 'item-detail-drawer',
  CUSTOMER_DETAILS = 'customer-detail-drawer',
  VENDOR_DETAILS = 'vendor-detail-drawer',
  INVENTORY_ADJUSTMENT_DETAILS = 'inventory-adjustment-drawer',
  CASHFLOW_TRNASACTION_DETAILS = 'cashflow-transaction-drawer',
  QUICK_CREATE_CUSTOMER = 'quick-create-customer',
  QUICK_CREATE_ITEM = 'quick-create-item',
  QUICK_WRITE_VENDOR = 'quick-write-vendor',
  CREDIT_NOTE_DETAILS = 'credit-note-detail-drawer',
  VENDOR_CREDIT_DETAILS = 'vendor-credit-detail-drawer',
  REFUND_CREDIT_NOTE_DETAILS = 'refund-credit-detail-drawer',
  REFUND_VENDOR_CREDIT_DETAILS = 'refund-vendor-detail-drawer',
  WAREHOUSE_TRANSFER_DETAILS = 'warehouse-transfer-detail-drawer',
  TAX_RATE_DETAILS = 'tax-rate-detail-drawer',
}
