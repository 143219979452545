/**
 * Decodes HTML entities in a string.
 * @param {string} encodedString - The string with HTML entities.
 * @returns {string} - Decoded string.
 */
export function decodeHtmlEntities(encodedString:string) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}


export function decodeUrl(encodedUrl: string): string {
  const replacements: { [key: string]: string } = {
      '&#x2F;': '/',
      '&#x3A;': ':',
      '&#x3F;': '?',
      '&#x3D;': '=',
      '&#x26;': '&',
      '&#x25;': '%',
      '&#x2E;': '.',
      '&#x2D;': '-',
      '&#x5F;': '_',
      '&#x2B;': '+',
      '&#x21;': '!',
      '&#x2A;': '*',
      '&#x27;': "'",
      '&#x28;': '(',
      '&#x29;': ')',
      '&#x2C;': ',',
      '&#x3B;': ';',
      '&#x40;': '@',
      '&#x23;': '#',
      '&amp;': '&'
      // Add more replacements if needed
  };

  return Object.entries(replacements).reduce(
      (url, [encoded, decoded]) => url.replace(new RegExp(encoded, 'g'), decoded),
      encodedUrl
  );
}

export function decodeObjectValues(obj: { [key: string]: any }): { [key: string]: any } {
  for (let key in obj) {
      if (typeof obj[key] === 'string') {
          obj[key] = decodeUrl(obj[key]);
      }
  }
  return obj;
}

export function decodeArrayOfObjects(arr: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
  return arr.map(obj => decodeObjectValues(obj));
}