// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  usePagination,
  useResizeColumns,
  useSortBy,
  useFlexLayout,
  useAsyncDebounce,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { sumBy } from 'lodash';
import Currencies from 'js-money/lib/currency';

import { useUpdateEffect } from '@/hooks';
import { saveInvoke } from '@/utils';

import '@/style/components/DataTable/DataTable.scss';

import TableNoResultsRow from './TableNoResultsRow';
import TableLoadingRow from './TableLoading';
import TableHeader from './TableHeader';
import TablePage from './TablePage';
import TableFooter from './TableFooter';
import TableRow from './TableRow';
import TableRows from './TableRows';
import TableCell from './TableCell';
import TableTBody from './TableTBody';
import TableContext from './TableContext';
import TablePagination from './TablePagination';
import TableWrapper from './TableWrapper';

import TableIndeterminateCheckboxRow from './TableIndeterminateCheckboxRow';
import TableIndeterminateCheckboxHeader from './TableIndeterminateCheckboxHeader';

import { useResizeObserver } from './utils';
import { decodeArrayOfObjects } from '@/utils/html';

/**
 * Datatable component.
 */
export function DataTable(props) {
  const {
    columns,
    data,

    onFetchData,

    onSelectedRowsChange,
    manualSortBy = false,
    manualPagination = true,
    selectionColumn = false,
    expandSubRows = true,
    expanded = {},
    rowClassNames,
    payload,
    expandable = false,
    noInitialFetch = false,

    pagesCount: controlledPageCount,

    // Pagination props.
    initialPageIndex = 0,
    initialPageSize = 10,

    updateDebounceTime = 200,
    selectionColumnWidth = 42,

    autoResetPage,
    autoResetExpanded,
    autoResetGroupBy,
    autoResetSelectedRows,
    autoResetSortBy,
    autoResetFilters,
    autoResetRowState,

    // Components
    TableHeaderRenderer,
    TablePageRenderer,
    TableWrapperRenderer,
    TableTBodyRenderer,
    TablePaginationRenderer,
    TableFooterRenderer,

    onColumnResizing,
    initialColumnsWidths,

    ...restProps
  } = props;

  const selectionColumnObj = {
    id: 'selection',
    disableResizing: true,
    minWidth: selectionColumnWidth,
    width: selectionColumnWidth,
    maxWidth: selectionColumnWidth,
    skeletonWidthMin: 100,
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: TableIndeterminateCheckboxHeader,
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: TableIndeterminateCheckboxRow,
    className: 'selection',
    ...(typeof selectionColumn === 'object' ? selectionColumn : {}),
  };
  console.log(data);
  
  const cleanData = decodeArrayOfObjects(data);
  
  console.log(cleanData);
  
  const totalQuantity = _.sumBy(cleanData, 'quantity')
  const totalSales = _.sumBy(cleanData, 'amount')

  if (window.location.pathname === '/items' && Object.hasOwn(cleanData[0]??{someKey:''}, 'receip_number')) {
    cleanData.sort((a, b) => a.receip_number.localeCompare(b.receip_number))
    data.sort((a, b) => a.receip_number.localeCompare(b.receip_number))
    // cleanData.sort((a, b) => b.receip_number.localeCompare(a.receip_number))  // TEST DESCENDING ORDER
    // data.sort((a, b) => b.receip_number.localeCompare(a.receip_number))
  }
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
        expanded,
        columnResizing: {
          columnWidths: initialColumnsWidths || {},
        },
      },
      manualPagination,
      pageCount: controlledPageCount,
      getSubRows: (row) => row.children,
      manualSortBy,
      expandSubRows,
      payload,

      autoResetPage,
      autoResetExpanded,
      autoResetGroupBy,
      autoResetSelectedRows,
      autoResetSortBy,
      autoResetFilters,
      autoResetRowState,

      ...restProps,
    },
    useSortBy,
    useExpanded,
    useResizeColumns,
    useFlexLayout,
    useSticky,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        ...(selectionColumn ? [selectionColumnObj] : []),
        ...columns,
      ]);
    },
  );

  const {
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = table;

  const isInitialMount = useRef(noInitialFetch);

  const onFetchDataDebounced = useAsyncDebounce((...args) => {
    saveInvoke(onFetchData, ...args);
  }, updateDebounceTime);

  // When these table states change, fetch new data!
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (window.location.pathname === '/receipts')
        table.setSortBy([{ id: 'receipt_number', desc: false }])
    } else {
      onFetchDataDebounced({ pageIndex, pageSize, sortBy });
    }
  }, [pageIndex, pageSize, sortBy, onFetchDataDebounced, table]);

  useUpdateEffect(() => {
    saveInvoke(onSelectedRowsChange, selectedFlatRows);
  }, [selectedRowIds, onSelectedRowsChange]);

  // Column resizing observer.
  useResizeObserver(table.state, (current, columnWidth, columnsResizing) => {
    onColumnResizing && onColumnResizing(current, columnWidth, columnsResizing);
  });

  return (
    <>
      <TableContext.Provider value={{ table, props }}>
        <TableWrapperRenderer>
          <TableHeaderRenderer />

          <TableTBodyRenderer>
            <TablePageRenderer />
          </TableTBodyRenderer>

          <TableFooterRenderer />
        </TableWrapperRenderer>

        <TablePaginationRenderer />
      </TableContext.Provider>
      {(window.location.pathname === '/items' && Object.hasOwn(cleanData[0]??{someKey:''}, 'receip_number'))?
        <div style={{
          color: 'black',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'right',
          width: 'calc(100% - 8px)',
          minWidth: '672px',
          maxWidth: 'calc(100% - 8px)',
          overflowX: 'scroll',
          borderTopWidth: '2px',
          borderColor: 'black',
          padding: '20px 0'
        }}>
          <div style={{
            minWidth: '200px',
            width: '40.6%'
          }}></div>
          <div style={{
            textAlign: 'right',
            minWidth: '100px',
            width: '14.85%'
          }}>
            Total Quantity:
          </div>
          <div style={{
            textAlign: 'right',
            minWidth: '100px',
            width: '14.85%'
          }}>
            { totalQuantity }
          </div>
          <div style={{
            textAlign: 'right',
            minWidth: '100px',
            width: '14.85%'
          }}>
            Total Sales:
          </div>
          <div style={{
            textAlign: 'right',
            minWidth: '100px',
            width: '14.85%'
          }}>
            { Currencies[`${cleanData[0].customer_currency_code}`].symbol }{ totalSales }
          </div>
        </div>
        :
        <></>
      }
    </>
  );
}

DataTable.defaultProps = {
  pagination: false,
  hidePaginationNoPages: true,
  hideTableHeader: false,

  size: null,
  spinnerProps: { size: 30 },

  expandToggleColumn: 1,
  expandColumnSpace: 0.8,

  autoResetPage: true,
  autoResetExpanded: true,
  autoResetGroupBy: true,
  autoResetSelectedRows: true,
  autoResetSortBy: true,
  autoResetFilters: true,
  autoResetRowState: true,

  TableHeaderRenderer: TableHeader,
  TableFooterRenderer: TableFooter,
  TableLoadingRenderer: TableLoadingRow,
  TablePageRenderer: TablePage,
  TableRowsRenderer: TableRows,
  TableRowRenderer: TableRow,
  TableCellRenderer: TableCell,
  TableWrapperRenderer: TableWrapper,
  TableTBodyRenderer: TableTBody,
  TablePaginationRenderer: TablePagination,
  TableNoResultsRowRenderer: TableNoResultsRow,

  noResults: '',
  payload: {},
};
